import logo from './logo.svg';
import { useEffect } from 'react';
import { useState } from 'react';
import './App.css';
import FrontPage from './component/FrontPage/FrontPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './component/pages/about/About';
import Facility from './component/pages/facility/Facility';
import Vision from './component/pages/vision-mission/Vision';
import Gallery from './component/pages/gallery/Gallery';
import Fee from './component/pages/fee/Fee';
import Contact from './component/pages/contact/Contact';
import Admissions from './component/pages/admissions/Admissions';
import Login from './component/login/Login';



// for MUi
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormDialog from './component/pages/popPage/pop'

function App() {

  const [showPopup,setShowpopup]=useState(false)
 
useEffect(()=>{

  setTimeout(()=>{
setShowpopup(true);
  },20000)

},[])
  
  return (
    <>
    
    <BrowserRouter>
    <Routes>
    <Route exact path='/' element={<FrontPage/>}></Route>
    <Route exact path='/about' element={<About/>}></Route>
    <Route exact path='/facility' element={<Facility/>}></Route>
    <Route exact path='/vision' element={<Vision/>}></Route>
    <Route exact path='/gallery' element={<Gallery/>}></Route>
    <Route exact path='/fee' element={<Fee/>}></Route>
    <Route exact path='/contact' element={<Contact/>}></Route>
    <Route exact path='/admissions' element={<Admissions/>}></Route>
    <Route exact path='/login' element={<Login/>}></Route>
    </Routes>
    </BrowserRouter>
   { showPopup?<FormDialog/>:null}
    </>
  );
}

export default App;
