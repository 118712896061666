import React from "react";
import logo from "../../assets/images/schoolLogo.png";
import "./Footer.css";
const Footer = () => {
  return (
    <footer>
      <div class="footer-top">
        <div class="footer-tab">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-lg-4">
                <div class="footer-logo">
                  <img
                    loading="lazy"
                    src={logo}
                    alt="Image"
                    class="img-fluid"
                    width="300"
                    height="60"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-md-3 mx-0 mx-sm-auto">
                <ul>
                  <li>
                    <a href="">
                      <strong>Home</strong>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="">Key Pillars</a>
                  </li>
                  <li>
                    <a href="">Learning Framework</a>
                  </li>
                  <li>
                    <a href="">Grades</a>
                  </li>
                  <li>
                    <a href="">Thought Shaping Programs</a>
                  </li>
                  <li>
                    <a href="">Sports Programs</a>
                  </li>
                  <li>
                    <a href="">Performing Arts Programs</a>
                  </li>
                  <li>
                    <a href="">Infrastructure</a>
                  </li>
                  <li>
                    <a href="">Educators</a>
                  </li>

                  <li>
                    <a href="">
                      <strong>Alumni</strong>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <strong>Curriculum</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-3 mx-0 mx-sm-auto">
                <ul>
                  <li>
                    {" "}
                    <a href="">
                      <strong>Life at Star Convent</strong>{" "}
                    </a>
                  </li>

                  <li>
                    <a href="">Our Culture</a>
                  </li>
                  <li>
                    <a href="">Learners Experience</a>
                  </li>
                  <li>
                    <a href="">Career Guidance</a>
                  </li>
                  <li>
                    <a href="">Our Alumini Journeys</a>
                  </li>
                  <li>
                    <a href="">Student Creators</a>
                  </li>

                  <li>
                    <a href="">
                      <strong>Awards</strong>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <strong>Careers</strong>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <strong>Contact us</strong>
                    </a>
                  </li>
                </ul>
                <ul>
                  <div class="d-flex navbar-button" style={{ width: "160px" }}>
                    <a href="" class="btn addmission" style={{ color: "#fff" }}>
                      Admission Enquiry
                    </a>
                  </div>
                </ul>
              </div>
              <div
                class="col-lg-2 col-md-2 m-auto"
                style={{ textAlign: " center" }}
              >
                <p>FOLLOW US</p>
                <a href="" target="_blank" rel="noopener">
                  {/* <img
                    loading="lazy"
                    src="https://www.billabonghighschool.com/wp-content/themes/billabong/assets/images/FB.png"
                    alt="Image"
                    width="45"
                    height="45"

                  /> */}

<span className="footerSocialIcon">

<i class="fa-brands fa-instagram"></i>
</span>
                </a>
                <a href="" target="_blank" rel="noopener">
                  {/* <img
                    loading="lazy"
                    src="https://www.billabonghighschool.com/wp-content/themes/billabong/assets/images/YT.png"
                    alt="Image"
					width="45"
                    height="45"
                  /> */}

<span className="footerSocialIcon">

<i class="fa-brands fa-facebook-f"></i>
</span>
                </a>
                <a href="" target="_blank" rel="noopener">
                  {/* <img
                    src="https://www.billabonghighschool.com/wp-content/themes/billabong/assets/images/IN.png"
                    alt="Image"
					width="45"
                    height="45"
                  /> */}

				 <span className="footerSocialIcon">

				 <i class="fa-brands fa-youtube"></i>
				 </span>
                </a>
              </div>
              <div class="col-lg-3 col-md-3 m-auto d-none">
                <ul>
                  <li>
                    <a href="" target="_blank">
                      Privacy policy
                    </a>
                  </li>
                </ul>
              </div>
              <div class="bottom">
                <h3 style={{ color: "#ff3786" }}>Our School Network</h3>
                <div class="row">
                  <div
                    class="col-lg-2 col-md-2 mx-0 mx-sm-auto"
                    style={{ textAlign: "left" }}
                  >
                    <b>Gandhi Bhag</b>
                    
                  </div>



				  <div
                    class="col-lg-2 col-md-2 mx-0 mx-sm-auto"
                    style={{ textAlign: "left" }}
                  >
                    <b>Kalamna</b>
                  </div>
               
                </div>
              </div>
              <div class="bottom">
                <div class="row">
                  <div class="copyright d-flex justify-content-between m-auto">
                    <p class="text-muted text-center">
                      Copyright © 2024 Star convent and High School. All rights
                      reserved | <a href="">Privacy policy</a>
                    </p>
                    {/* <!--	<ul class="pt-3">
									<li><a href="https://www.billabonghighschool.com/privacy-policy/">Privacy policy</a></li>
									</ul> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
