import React from 'react'
import './ArtsPrograms.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArtsPrograms = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <section class="programs-sec Performing-Arts-Programs performingPrograms" id="performingPrograms">
			<div class="container">
				<div class="sec-heading">
					<h5>Arts Programs</h5>
					<p>We offer our students various programs to pursue any passion in visual or performing arts. Each of these programs is delivered by experienced instructors. </p>
				</div>
				<div class="gallery-carosel">
					<div class="gallery">
						<div class="owl-carousel owl-theme gallery-carousel">
                        <Slider {...settings}>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg1" ></div>
										<div class="block-overlay">
											<h4>Pottery ^</h4>
											<p>
												Fosters creativity, patience and fine motor skills, providing a tactile and meditative outlet for self-expression.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg2" ></div>
										<div class="block-overlay">
											<h4>Dance ^</h4>
											<p>
												Cultivates physical fitness, grace and self-confidence while fostering artistic expression and cultural appreciation.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg3" ></div>
										<div class="block-overlay">
											<h4>Painting ^</h4>
											<p>
												Stimulates creativity, fine motor skills and emotional intelligence, offering a therapeutic and expressive artistic outlet.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg4" ></div>
										<div class="block-overlay">
											<h4>Drums ^</h4>
											<p>
												Develops rhythm, coordination and teamwork, promoting both physical and emotional well-being through musical expression.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg5" ></div>
										<div class="block-overlay">
											<h4>Craft ^</h4>
											<p>
												Enhances creativity, attention to detail and problem-solving skills with a diverse platform for artistic exploration.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg6" ></div>
										<div class="block-overlay">
											<h4>Drama ^</h4>
											<p>
												Builds confidence, communication skills and empathy through theatrical expression, fostering a well-rounded emotional intelligence.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg7" ></div>
										<div class="block-overlay">
											<h4>Guitar ^</h4>
											<p>
												Improves musical skills, patience and discipline while providing a versatile outlet for artistic self-expression.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg8" ></div>
										<div class="block-overlay">
											<h4>Keyboard ^</h4>
											<p>
												Enhances musical proficiency, focus and cognitive abilities, offering a dynamic avenue for creative expression.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg9" ></div>
										<div class="block-overlay">
											<h4>Music ^</h4>
											<p>
												Stimulates cognitive development, emotional intelligence and teamwork, fostering a lifelong appreciation for the arts.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg10" ></div>
										<div class="block-overlay">
											<h4>Speech ^</h4>
											<p>
												Cultivates effective communication, critical thinking and confidence, providing a valuable skill set for personal and professional success.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg11" ></div>
										<div class="block-overlay">
											<h4>Theatre ^</h4>
											<p>
												Promotes collaboration, creativity, appreciation and empathy through live performances.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg12"></div>
										<div class="block-overlay">
											<h4>Vocals ^</h4>
											<p>
												Develops vocal technique, self-expression and confidence, providing a powerful avenue for artistic and emotional exploration.											</p>
										</div>
									</div>
								</div>
							</div>
                            </Slider>
													</div>
					</div>
				</div>
			</div>
		</section>
  )
}

export default ArtsPrograms
