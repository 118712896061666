import React from 'react'
import './Login.css'
import logo from '../../assets/images/schoolLogo.png'
const Login = () => {
  return (
    <div className='p-0'>
    <div class="container-fluid">
    <div class="row">
        <div class="col-md-6 loginleft">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="loginlogo"><img src={logo} class="img-fluid" alt=""/></div>
                </div>
            </div>
        </div>
        <div class="col-md-6 align-self-center">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="loginright">
                        <h3>Login to Khan Clinic</h3>
                        <form class="pt-2">
                          <div class="mb-3">
                              <label class="form-label">Email address</label>
                              <input type="email" class="form-control"  id="email" name="email"/>
                          </div>
                          <div class="mb-3">
                              <label class="form-label">Password</label>
                              <input type="password" class="form-control"  id="pass" name="pass"/>
                          </div>
                          <div class="mb-3">
                              <div class="row">
                                  <div class="col-6">
                                      <a href="#" class="btn btn-primary sign" >Login</a>
                                  </div>
                                  <div class="col-6 text-right pt-2"><a href="#" class="link-primary">Forgot your password?</a></div>
                              </div>
                          </div>
                          <div class="mb-3">
                              <div class="or"> <span>or</span> </div>
                          </div>
                          
                          <div class="mb-3">
                              <a href="#" class="btn btn-default d-block sign"><img src="img/li2.jpg" alt=""/>Sign in with Facebook</a>
                          </div>
                          <div class="mb-3">
                              <a href="#" class="btn btn-default d-block sign"><img src="img/li3.jpg" alt=""/>Sign in with Google</a>
                          </div>
                      

                          <div class="mb-3">
                              <p class="pt-3">Logging in is deemed acceptance of our <a href="#" class="link-primary">terms View our privacy policy</a></p>
                              <p>Struggling to login? <a href="#" class="link-primary">Visit our support page</a></p>
                          </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


</div>
  )
}

export default Login
