import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/schoolLogo.png";
import whatsappImg from "../../img/whatsapp.png";

// const logoPath = path.resolve(__dirname, '../../../public/img/schoolLogo.png');
const Header = () => {
  return (
    <header class="sticky-top header">
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="">
            <img src={logo} alt="Image" width="300" height="60 " />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
{/* 
              <li class="nav-item ">
                <Link class="nav-link" id="homeDropdown" to="/">
                  Home <span class="" data-bs-toggle="dropdown"></span>
                </Link>
              </li> */}

			  <li class="nav-item dropdown d-lg-block m-sm-0 m-2">
                <Link class="nav-link" id="lifeDropdown" to="/">
                  Home <span class=""></span>
                </Link>
              </li>




              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="lifeDropdown" to="/about">
                  About <span class=""></span>
                </Link>
              </li>
              <li class="nav-item dropdown  d-block d-lg-none  m-sm-0 m-2">
                <Link class="nav-link p-0" id="lifemDropdown" to="/about">
                  About <span class=""></span>
                </Link>
              </li>
              <li class="nav-item   d-block d-lg-none  m-sm-0 m-2">
                <Link class="nav-link p-0" id="acdmDropdown" to="/facility">
                  Facility
                  <span class=""></span>
                </Link>
              </li>
              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="acdmDropdown" to="/facility">
                  Facility
                  <span class="" data-bs-toggle="dropdown"></span>
                </Link>
              </li>


			  <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="acdmDropdown" to="/gallery">
                  Gallery
                  <span class="" data-bs-toggle="dropdown"></span>
                </Link>
              </li>

              {/* <li class="nav-item">
                <Link class="nav-link p-0 p-lg-2" to="/gallery">
                  Gallery 
                </Link>
              </li> */}
              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="schoolDropdown" to="/vision">
                  Vision & Mission <span class=""></span>
                </Link>
              </li>

              <li class="nav-item dropdown  d-block d-lg-none   m-sm-0 m-2">
                <Link class="nav-link p-0" id="schoolmDropdown" to="/vision">
                  Vision & Mission<span class=""></span>
                </Link>
              </li>
              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="schoolDropdown" to="/fee">
                  Fee <span class="" data-bs-toggle="dropdown"></span>
                </Link>
              </li>
              <li class="nav-item dropdown  d-block d-lg-none  m-sm-0 m-2">
                <Link class="nav-link p-0" id="schoolmDropdown" to="/fee">
                  Free<span class=""></span>
                </Link>
              </li>
              <li class="nav-item dropdown  d-block d-lg-none  m-sm-0 m-2">
                <Link class="nav-link p-0" id="schoolmDropdown" to="/contact">
                  Contact<span class=""></span>
                </Link>
              </li>

              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="schoolDropdown" to="/contact">
                  Contact <span class=""></span>
                </Link>
              </li>

              <li class="nav-item dropdown  d-block d-lg-none  m-sm-0 m-2">
                <Link class="nav-link p-0" id="schoolmDropdown" to="/login">
                  Login<span class=""></span>
                </Link>
              </li>

              <li class="nav-item dropdown d-none d-lg-block  m-sm-0 m-2">
                <Link class="nav-link" id="schoolDropdown" to="/login">
                  Login <span class=""></span>
                </Link>
              </li>
            </ul>
            <div class="d-flex navbar-button">
              <Link to="/admissions" class="btn addmission">
                Admissions
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div id="mynewButton" class="d-flex navbar-button">
        <Link to="/admissions">
          {" "}
          <button class="btn addmission sidebarBtn">Admissions</button>
        </Link>
      </div>

      <div class="whatapp">
        <a href="https://api.whatsapp.com/send?phone=7020948836">
          <img src={whatsappImg} alt="" />
        </a>
      </div>
    </header>
  );
};

export default Header;
