import React from 'react'
import './Wellcome.css'
const Wellcome = () => {
  return (
    <section class="welcome-sec" >
			<div class="container">
				<div class="row text-center">
					<div class="col-md-12">
						<div class="welcome_new_sec">
							<div class="sec-heading text-center">
								<h5>WELCOME TO ‘THE SCHOOL OF THOUGHT’</h5>
								<p>"Welcome to Star Convent Pre Primary School, where we merge tradition with innovation to provide the highest quality education for children aged 3 to 15. Our commitment is to harness the power of technology alongside proven teaching methods to cultivate young minds for the challenges of tomorrow. From our early years program, where interactive digital learning tools complement hands-on activities, to our comprehensive middle school curriculum integrating coding, robotics, and STEM education, we prepare students to excel in a rapidly evolving world. At Star Convent, we believe in nurturing critical thinking, creativity, and collaboration, equipping our students with the skills they need to thrive in the digital age while instilling a strong foundation of values and character."</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
  )
}

export default Wellcome
