import React from 'react'
import './Facility.css'
import Footer from '../../footer/Footer'
import Header from '../../header/Header'
const Facility = () => {
  return (
    <>
    <Header/>
    <section class="inner-header divider layer-overlay overlay-theme-colored-4 facility" data-bg-img="images/bg-vision.jpg">
<div class="container pt-60 pb-40">
<div class="section-content">
<div class="row"> 
<div class="col-md-12 facility-box1">
<h2 class="text-theme-color-2 font-36">Facilities</h2>
<ol class="breadcrumb text-left mt-10 white">
{/* <li><a href="index-2.html">Home</a></li> */}
{/* <li class="active">Facilities</li> */}
</ol>
</div>
</div>
</div>
</div>
</section>

<section className='facility-box'>
<div class="container ">
<div class="section-content">


<div class="row pt-40 pb-30" id="camp">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Summer <span class="text-theme-color-2">Camp </span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p><strong>Fun filled Activities</strong></p>
<li>Puppet Making.</li>
<li>Story Sessions.</li>
<li>Cooking without Fire.</li>
<li>Sand Play.</li>
<li>Clay Making.</li>
<li>Arts Craft.</li>
<li>Candle Making.</li>
<li>Pottery.</li>
<li>Puppet Show.</li>
<li>Fun with Papper</li>
<p> <b>Note:- Summer School fees is not includeed in the tution.</b></p>
</div>
<div class="col-md-4 facility-img1">
<a href="">
{/* <img border="0" alt="Summer Camp, Little Senators" src={"../../../../public/img/7c.jpg"} width="406" height="496"/> */}


</a>

  
  
  </div>
</div>



<div class="row pt-40 pb-30" id="computer">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Class <span class="text-theme-color-2">Room </span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>we believe that children need space to grow and develop in and our large, 
free flowing classrooms enable children to do just that. As well as featuring 
key areas of learning and banks of accessible resources, our children have ample 
rest and quiet areas with adjoining pantries, en-suite bathroom facilities and 
immediate access to the gardens.</p>
</div>
<div class="col-md-4 facility-img2">
  {/* <img src="images/fac/1.jpg" alt="" class="img-thumbnail" width="500" height="318"/>
   */}
  </div>
</div>

<div class="row pt-40 pb-30" id="science">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Activity <span class="text-theme-color-2">Room </span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>In the early years of life, children are interested and curious about 
everything around them.<br/>
where children can paint, use chalk and select various materials to cut, stick 
and paint and develop their own creative ideas, including junk modeling.</p>
</div>
<div class="col-md-4  facility-img3">
  {/* <img src="images/fac/2.jpg" alt="" class="img-thumbnail" width="500" height="318"/> */}
  
  </div>
</div>

<div class="row pt-40 pb-30" id="math">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10"> Playing <span class="text-theme-color-2">Area</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>when the temperatures soar, our children have extensive indoor play spaces to 
climb, run, jump and crawl through..</p>
</div>
<div class="col-md-4 facility-img4">
  {/* <img src="images/fac/3.jpg" alt="" class="img-thumbnail" width="500" height="318"/> */}
  
  </div>
</div>

<div class="row pt-40 pb-30" id="smart">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Outdoor <span class="text-theme-color-2">Fun </span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>Each of our facilities has safety-certified playgrounds that allow separate 
space for Infants/Toddlers, Little Senators, Preschool and Pre-Kindergarten 
classes. All playing areas have exceptional playground equipment.</p>
</div>
<div class="col-md-4 facility-img5">
  {/* <img src="images/fac/4.jpg" alt="" class="img-thumbnail" width="500" height="318"/> */}
  
  </div>
</div>

<div class="row pt-40 pb-30" id="language">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Splash  <span class="text-theme-color-2">Pool</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">it’s not just for cooling down but helps to build your 
child’s confidence and self-esteem too. Our water confidence programmes help 
your little ones develop resilience and stamina.</p>
</div>
<div class="col-md-4 facility-img6">
  {/* <img src="images/fac/5.jpg" alt="" class="img-thumbnail" width="500" height="318"/> */}
  
  </div>
</div>

<div class="row pt-40 pb-30" id="transport">
<div class="col-md-8">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Sandpit <span class="text-theme-color-2">Activity</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">Children have always been intrigued by sand. Sand is a 
foundational part of the ground in the same way that dirt or clay is, making it 
available to children for centuries. Before the arrival of the toys and 
electronics of today’s society, children were forced to find creative methods to 
play with the outdoor materials found all around them, including sand. Children 
have played in sand by digging, sifting, building, pouring, and exploring 
throughout time, and they should be encouraged to continue this method of play. 
Sand is very well-suited to the urge to explore and to the imagination inherent 
in young children.</p>
</div>
<div class="col-md-4 facility-img7">
  {/* <img src="images/fac/6.jpg" alt="" class="img-thumbnail" width="500" height="318"/> */}
  
  </div>
</div>

</div>
</div>
</section>
<Footer/>
      
    </>
  )
}

export default Facility
