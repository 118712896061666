import React from 'react'
import './ThoughtShaping.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const ThoughtShaping = () => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <section class="grades-sec unique-programs" id="thoughtShaping">
    <div class="container">
        <div class="sec-heading">
            <h5>Thought-shaping Programs</h5>
            <p>Our unique programs are aimed at encouraging collaboration, creativity and innovation in students. These programs enable students to apply theoretical concepts to practical situations, using the latest technologies, while bringing their ideas to life. 
</p>
        </div>
        <div class="linksrow">
            <div class="row">
                <div class="owl-carousel owl-theme grades-carousel">

                <Slider {...settings}>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg1"></div>
                            <div class="block-overlay">
                                <h4>Critical Thinking Program ^ </h4>
                                <p>Developed in collaboration with Dr. Roy van den Brink-Budgen, the Critical Thinking Program aims to develop independent thinking, problem-solving and creativity among learners.</p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg2" ></div>
                            <div class="block-overlay">
                                <h4>Maker Program ^</h4>
                                <p>The Maker Program encourages practical hands-on learning within the dedicated Maker Lab. It aims to develop the abilities of future designers, innovators, entrepreneurs and scientists.</p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg3"></div>
                            <div class="block-overlay">
                                <h4>AI & Drone Program ^ </h4>
                                <p>The AI & Drone Program focuses on the latest technologies, preparing youngsters for the future. It equips them with valuable technical skills for tech-based careers. </p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg4"></div>
                            <div class="block-overlay">
                                <h4>Genius Hour Program ^</h4>
                                <p>The Genius Hour Program enables a child to deep dive into a topic they’re passionate about through a graded project. This is a student-directed program. </p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg5" ></div>
                            <div class="block-overlay">
                                <h4>STEM & Robotics Program ^</h4>
                                <p>The STEM & Robotics Program combines the principles of Science, Technology, Engineering and Mathematics and applies them to the engaging field of Robotics.</p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg6" ></div>
                            <div class="block-overlay">
                                <h4>Video Recording & Editing Program ^</h4>
                                <p>Conducted within a dedicated studio space, the Video Recording & Editing Program offers exposure to industry-level software and hardware.</p>
                            </div>
                        </div>
                    </div>
                                                <div class="item">
                        <div class="block">
                            <div class="bg bg7" ></div>
                            <div class="block-overlay">
                                <h4>Leadership Program ^</h4>
                                <p>To prepare future leaders, the Leadership Program hones students’ skills like public speaking, confidence building, goal setting, time management and others. </p>
                            </div>
                        </div>
                    </div>
                    </Slider>
                                            </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default ThoughtShaping
