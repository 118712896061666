import React from 'react'
import './InfraSTructure.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const InfraSTructure = () => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <section class="spaces-sec infraSTructure" id="infraSTructure">
    <div class="container">
        <div class="sec-heading text-center">
            <h5>Thoughtfully designed infrastructure</h5>
            <p>When students are immersed in an environment that encourages new thoughts and ideas, they become engaged learners. Every aspect of our school is thoughtfully designed to support our unique learning initiatives.</p>
        </div>
        <div class="spaces-row spaces-desk laptopShow">
            <div class="row">
                                        <div class="col-md-4">
                    <div class="block">
                        <div class="bg bg1" >
                        </div>
                        <div class="block-overlay">
                            <h4>Library</h4>
                        </div>
                    </div>
                </div>
                                        <div class="col-md-4">
                    <div class="block">
                        <div class="bg bg2" >
                        </div>
                        <div class="block-overlay">
                            <h4>Digitally Enabled Classrooms</h4>
                        </div>
                    </div>
                </div>
                                        <div class="col-md-4">
                    <div class="block">
                        <div class="bg bg3" >
                        </div>
                        <div class="block-overlay">
                            <h4>Art Studio</h4>
                        </div>
                    </div>
                </div>
                                        <div class="col-md-6">
                    <div class="block">
                        <div class="bg bg4">
                        </div>
                        <div class="block-overlay">
                            <h4>Sports & Extracurricular Facilities for Football</h4>
                        </div>
                    </div>
                </div>
                                        <div class="col-md-3">
                    <div class="block">
                        <div class="bg bg5" >
                        </div>
                        <div class="block-overlay">
                            <h4>Dance studio</h4>
                        </div>
                    </div>
                </div>
                                        <div class="col-md-3">
                    <div class="block">
                        <div class="bg bg6" >
                        </div>
                        <div class="block-overlay">
                            <h4>Science Labs</h4>
                        </div>
                    </div>
                </div>
                                    </div>
        </div>


        <div class="spaces-mb">
            <div class="spaces-row">
                <div class="row">
                    <div class="owl-carousel owl-theme spaces-carousel">

                    <Slider {...settings}>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg1"
                                   >
                                </div>
                                <div class="block-overlay">
                                    <h4>Library</h4>
                                </div>
                            </div>
                        </div>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg2"
                                    >
                                </div>
                                <div class="block-overlay">
                                    <h4>Digitally Enabled Classrooms</h4>
                                </div>
                            </div>
                        </div>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg3"
                                    >
                                </div>
                                <div class="block-overlay">
                                    <h4>Art Studio</h4>
                                </div>
                            </div>
                        </div>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg4"
                                    >
                                </div>
                                <div class="block-overlay">
                                    <h4>Sports & Extracurricular Facilities for Football</h4>
                                </div>
                            </div>
                        </div>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg5"
                                    >
                                </div>
                                <div class="block-overlay">
                                    <h4>Dance studio</h4>
                                </div>
                            </div>
                        </div>
                                                        <div class="item">
                            <div class="block">
                                <div class="bg bg6"
                                    >
                                </div>
                                <div class="block-overlay">
                                    <h4>Science Labs</h4>
                                </div>
                            </div>
                        </div>
                        </Slider>
                                                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default InfraSTructure
