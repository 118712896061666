import React from "react";
import "./KeyPillers.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const KeyPillers = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      class="key-pillers"
      id="keyPillars"
      style={{ backgroundColor: "#eaeaea" }}
    >
      <div class="container">
        <div class="sec-heading">
          <h5>5 KEY PILLARS THAT MAKE LEARNING THOUGHTFUL</h5>
          <p>
            Our core aim is to cultivate enduring and engaging learning
            experiences that will leave an indelible mark on children throughout
            their lives. Our commitment to nurturing creative thinkers is driven
            by a robust educational ecosystem comprising a dynamic curriculum,
            personalised learning facilitated by dedicated educators and
            distinctive programs and initiatives that hone a global perspective.
          </p>
        </div>

        <div class="key">
          <div class="row">
            <div class="owl-carousel owl-theme pillars-carousel">
              <Slider {...settings}>
                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Neuroscience_final.jpg"
                        alt="Neuroscience_final"
                        class="img-fluid"
                      />
                    </div>

                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Neuroscience-based_final.jpg"
                        alt="Neuroscience-based_final"
                        class="hover-img"
                      />
                      <h4>Activity-based Learning</h4>
                      <p>
                        {" "}
                        for pre-primary students is centered around hands-on,
                        experiential activities that engage their senses and
                        promote active learning. Here are some key details:{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Personalized_final.jpg"
                        alt="Personalized_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Personalized-learning_final.jpg"
                        alt="Personalized-learning_final"
                        class="hover-img"
                      />
                      <h4>Personalized learning</h4>
                      <p>
                        Our industry-best student-teacher ratio across grades
                        has positively impacted learning outcomes among
                        students. This shift from a teacher-centric approach to
                        a learner-centric approach enables students to take an
                        active role in their learning by setting goals, tracking
                        their progress and reflecting on their learning.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Thought_final.jpg"
                        alt="Thought_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Thought-Shaping_final.jpg"
                        alt="Thought-Shaping_final"
                        class="hover-img"
                      />
                      <h4>Thought-shaping programs</h4>
                      <p>
                        A host of specially designed programs that foster
                        innovation and independent thinking. These include the
                        Critical Thinking Program, Genius Hour Program, Maker
                        Program and others. The programs also ensure the
                        holistic development of each student within the
                        classroom and beyond.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Beyond_final.jpg"
                        alt="Beyond_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/BeyondAcademics_final.jpg"
                        alt="BeyondAcademics_final"
                        class="hover-img"
                      />
                      <h4>Collaborative Learning</h4>
                      <p>
                        Encourage children to work together in groups or pairs,
                        fostering communication skills, teamwork, and social
                        development.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Beyond_final.jpg"
                        alt="Beyond_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/BeyondAcademics_final.jpg"
                        alt="BeyondAcademics_final"
                        class="hover-img"
                      />
                      <h4>Play-based Approach</h4>
                      <p>
                        Activities should mimic play as much as possible to keep
                        children engaged and interested. This can include games,
                        puzzles, role-playing, and interactive toys.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Beyond_final.jpg"
                        alt="Beyond_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/BeyondAcademics_final.jpg"
                        alt="BeyondAcademics_final"
                        class="hover-img"
                      />
                      <h4>Outdoor Exploration</h4>
                      <p>
                      Incorporate outdoor activities and field trips to encourage exploration of the natural environment, promoting curiosity and a sense of wonder
                      </p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div class="block">
                    <div class="bg">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Global_final.jpg"
                        alt="Global_final"
                        class="img-fluid"
                      />
                    </div>
                    <div class="block-overlay">
                      <img
                        loading="lazy"
                        src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Global-Exposure_final.jpg"
                        alt="Global-Exposure_final"
                        class="hover-img"
                      />
                      <h4>Multi-sensory Learning</h4>
                      <p>
                        Incorporate activities that stimulate multiple senses
                        such as sight, touch, hearing, and sometimes even taste
                        and smell. This enhances retention and understanding.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyPillers;
