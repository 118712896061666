import React from 'react'
import './SliderMain.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sliderBig1 from '../../assets/images/sliderBig1.jpg'
import sliderBig2 from '../../assets/images/sliderBig2.jpg'
import slider3 from '../../assets/images/BHIS-CIE-Grade11-Banners-2024-D-View (1).jpg'

const SliderMain = () => {
  var settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1
  };

    
  return (
    <section class="banner__video">
    <div class="box1">
    <Slider {...settings}>
        <div class="item">
                <img src={sliderBig1} class="dest1" alt="" />
                {/* <img src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/BHIS-COCO-AdmissionPage-mobilebanner.jpg" class="mobile1" alt="" /> */}
        </div>
      
        <div class="item">
             <img src={slider3}  alt="Image"  class="dest1" loading="lazy"  />
            {/* <img src="https://www.billabonghighschool.com/NewDesign2023-mulund/wp-content/uploads/2024/02/Banner_Cycle_600-x-600_mobile.jpg" class="mobile1" alt="" loading="lazy"/> */}
        </div>


        <div class="item">
             <img src={sliderBig2} alt="Image"  class="dest1" loading="lazy"  />
            {/* <img src="https://www.billabonghighschool.com/NewDesign2023-mulund/wp-content/uploads/2024/02/Banner_Cycle_600-x-600_mobile.jpg" class="mobile1" alt="" loading="lazy"/> */}
        </div>
             
        </Slider>     
         
     </div>



</section>
  )
}

export default SliderMain
