import React from 'react'
import './Fee.css'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
const Fee = () => {
  return (
    <>
    <Header/>
      <section class="inner-header divider layer-overlay overlay-theme-colored-4 Fee" data-bg-img="images/bg-aboutus.jpg">
<div class="container pt-60 pb-40">
<div class="section-content">
<div class="row"> 
<div class="col-md-12 Fee-box">
<h2 class="text-theme-color-2 font-36">Fee Structure</h2>
<ol class="breadcrumb text-left mt-10 white">
<li><a href="index-2.html">Home</a></li>
<li><a href="#">Fee Structure</a></li>
<li class="active">Fee Structure</li>
</ol>
</div>
</div>
</div>
</div>
</section>

<section>
<div class="container">
<div class="section-content">
<div class="row pt-40">
<div class="col-md-12">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Fees Structure From: <span class="text-theme-color-2">
9.00 AM till 12.30 PM</span></h3>
<table border="0" cellpadding="4" cellspacing="4" style={{borderCollapse:"collapse"}} bordercolor="#111111" width="100%">
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%"><font color="#000000">Admission Fee (One Time) (Once only at 
    the time of admission) </font></td>
    <td width="48%"><font color="#000000">Rs. 30,000/- </font></td>
  </tr>
  <tr>
    <td width="52%"><font color="#000000">Security ( Refundable)</font></td>
    <td width="48%"><font color="#000000">Rs. 2,500/-</font></td>
  </tr>
  <tr>
    <td width="52%"><font color="#000000">Quarterly (Qtly. Rs.12000 x 3)</font></td>
    <td width="48%"><font color="#000000">Rs. 36,000/-</font></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%"><b><font color="#000000">Total</font></b></td>
    <td width="48%"><b><font color="#000000">Rs. 68,500/-</font></b></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="100%" colspan="2">

<section>
    <font size="5">Fees Structure From: </font> <span class="text-theme-color-2">
    <font size="5">9.00 AM till 3.00 PM</font></span></section></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Admission Fee (One Time) (Once only at the time of 
    admission) </font>
</section>

    </td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 30,000/- </font>
</section>

    </td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Security ( Refundable)</font></section></td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 2,500/-</font></section></td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Quarterly (Qtly. Rs.16000 x 3)</font></section></td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 48,000/-</font></section></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <b><font color="#000000">Total</font></b></section></td>
    <td width="48%">

<section>
    <b><font color="#000000">Rs. 80,500/-</font></b></section></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="100%" colspan="2">

<section>
    <font size="5">Fees Structure From: </font> <span class="text-theme-color-2">
    <font size="5">9.00 AM till 4.00 PM</font></span></section></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Admission Fee (One Time) (Once only at the time of 
    admission) </font>
</section>

    </td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 30,000/- </font>
</section>

    </td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Security ( Refundable)</font></section></td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 2,500/-</font></section></td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <font color="#000000">Quarterly (Qtly. Rs.17000 x 3)</font></section></td>
    <td width="48%">

<section>
    <font color="#000000">Rs. 51,000/-</font></section></td>
  </tr>
  <tr>
    <td width="52%">&nbsp;</td>
    <td width="48%">&nbsp;</td>
  </tr>
  <tr>
    <td width="52%">

<section>
    <b><font color="#000000">Total</font></b></section></td>
    <td width="48%">

<section>
    <b><font color="#000000">Rs. 83,500/-</font></b></section></td>
  </tr>
  <tr>
    <td width="100%" colspan="2"><b><font size="2">Fees once deposited is non 
    Refundable.<br/>
    <br/>
    Fees Will be charged for the period child retains the seat otherwise 
    re-admission will be charged provided the seat is available.)<br/>
    <br/>
    Summer camp available during the break, which is not included in the school 
    fees.<br/>
    <br/>
    Fees will be charged for summer break (Mid May to June)<br/>
    <br/>
    Fees can be paid by CASH<br/>
    <br/>
    Cheque in favour of LITTLE SENATORS NURSERY SCHOOL.<br/>
    <br/>
    CREDIT CARD ( 2.5% bank charge extra)<br/>
    <br/>
    Security fee will be refunded after a written notice of one month. <br/>
    <br/>
    Security refund should be collected within 3 months of your ward leaving the 
    school.<br/>
    <br/>
    Summer Camp available during summer break.<br/>
    <br/>
    Cheque in favour of LITTLE SENATORS NURSERY SCHOOL<br/>
    <br/>
    * Rs.200/- would be charged extra in case of dishonored cheque.</font></b><br/>
&nbsp;</td>
  </tr>
</table>
</div>
</div>
<hr/>

</div>
</div>
</section>
<Footer/>
    </>
  )
}

export default Fee
