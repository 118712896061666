import React from 'react'
import './Admissions.css'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
import image from '../../../assets/images/sliderBig1.jpg'
const Admissions = () => {

    const divStyle = {
        height: "35px",
        borderRadius: "10px",
        border: "#000",
        width: "80%",
        fontSize: "16px",
        marginBottom: "6px",
        paddingLeft: "5px",
        float: "right"

    }
    return (
        <>
        <Header/>
            <section class="admission-banner-sec">
                <div class="bg">
                    <picture>
                        <source media="(max-width: 767px)" srcset={image} />
                        <img  style={{width:'100%'}} src={image} />
                    </picture>
                </div>
                <div class="admission-banner-caption h-100">
                    <div class="container overlayer h-100">
                        <div class="row h-100 justify-content-between">
                            <div class="col-lg-6 col-md-4">
                                <div class="adm-img">
                                    <img src="" alt="" class="w-75" />
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6 align-self-center">
                                <div class="admission-form">
                                    <h5>ADMISSION ENQUIRY </h5>
                                    <p>All fields are mandatory</p>
                                    <form action="" method="post" name="enquiry-form">
                                        <div class="form-group">
                                            <select name="AcademicYear" class="form-control form-select" required="required">
                                                <option value="">Select Academic Year</option>
                                                <option value="2024 2025">2024-2025</option>
                                                {/* <!-- <option value="">2025-2026</option> --> */}
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" name="First_Name" pattern="^[a-zA-Z_ ]*$" title="Please enter only alphabets" placeholder="Student Name*"
                                                class="form-control" />
                                        </div>
                                        <div class="form-group">
                                            <input type="text" name="Last_Name" placeholder="Parent Name*"
                                                class="form-control" required="required" />
                                        </div>
                                        <div class="form-group">
                                            <input type="email" name="Email" placeholder="Parent Email ID*"
                                                class="form-control" required="required" />
                                        </div>
                                        <div class="form-group">
                                            <input type="tel" name="Mobile" pattern="[0-9]{10}" title="10 digit numerical only" maxlength="10" placeholder="Mobile Number"
                                                class="form-control" />
                                        </div>
                                        <div class="form-group">
                                            <select required="" name="State" class="form-control" onchange="showBranch(this.value)">
                                                <option value="">-Select Location-</option>
                                                <option value="GUJARAT">Gujarat</option>
                                                <option value="KERALA">Kerala</option>
                                                <option value="MADHYAPRADESH">Madhya Pradesh</option>
                                                <option value="MAHARASHTRA">Maharashtra</option>
                                                <option value="PUDUCHERRY">Puducherry</option>
                                                <option value="TAMILNADU">Tamil Nadu</option>
                                                <option value="TELANGANA">Telangana</option>
                                                <option value="UTTARPRADESH">Uttar Pradesh</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <div id="txtHint4">
                                                <select name="branch" required class="form-control" >
                                                    <option value="" >Select Branch </option>
                                                </select>
                                            </div> </div>
                                        <div class="form-group">
                                            <div id="txtHint5">
                                                <select name="Grade" required class="form-control">
                                                    <option value="" >Select Grade </option>
                                                </select>
                                            </div>
                                        </div>



                                        <div class="d-flex">
                                            
                                            <div class="form-group ms-auto">
                                                <input type="hidden" id="capsum1" name="capsum" class="form-control" />


                                            </div>
                                            


                                           

                                            
                                        </div>
                                        <div class="form-check checkboxx">
                                            <input type="checkbox" class="form-check-input" id="check2" name="option2"
                                                value="something" checked />
                                            <label class="form-check-label" for="check2">
                                                <p>By clicking on Submit, I allow all communication from Star Convent and  High
                                                     School</p>
                                            </label>
                                        </div>
                                        <button class="btn-submit" >Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Admissions
