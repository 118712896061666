import React from "react";
import "./Contact.css";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
const Contact = () => {
  return (
    <>
      <Header />
      <section
        class="inner-header divider layer-overlay overlay-theme-colored-4 contact"
        data-bg-img="images/bg1.jpg"
      >
        <div class="container pt-60 pb-40">
          <div class="section-content">
            <div class="row">
              <div class="col-md-12 contact-box">
                {/* <h2 class="text-theme-color-2 font-36">Contact</h2> */}
                <ol class="breadcrumb text-left mt-10 white">
                  <li>
                    {/* <a href="index-2.html">Home</a> */}
                  </li>
                  {/* <li class="active">Contact</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-box">
        <div class="container">
          <div class="section-content">
            <div class="row pt-60 pb-20">
            <div class="col-sm-12 col-md-4  mb-sm-0 mb-4">
                <div class="contact-info text-center bg-silver-light border-1px pt-60 pb-60">
                  <i class="fa fa-phone font-36 mb-10 text-theme-color-2"></i>
                  <h4>Call Us</h4>
                  <h5 class="text-gray">
                    <a href="tel:+91 11 4056 7310">+91 11 4056 7310</a>
                  </h5>
                </div>
              </div>

              <div class="col-sm-12 col-md-4  mb-sm-0 mb-4">
                <div class="contact-info text-center bg-silver-light border-1px pt-60 pb-60">
                  <i class="fa fa-phone font-36 mb-10 text-theme-color-2"></i>
                  <h4>Call Us</h4>
                  <h5 class="text-gray">
                    <a href="tel:+91 11 4056 7310">+91 11 4056 7310</a>
                  </h5>
                </div>
              </div>

              <div class="col-sm-12 col-md-4  mb-sm-0 mb-4">
                <div class="contact-info text-center bg-silver-light border-1px pt-60 pb-60">
                  <i class="fa fa-envelope font-36 mb-10 text-theme-color-2"></i>
                  <h4>Email</h4>
                  <h5 class="text-gray">
                    <a href="mailto:info@littlesenators.com">
                      info@littlesenators.com
                    </a>
                    <br />                 
                  </h5>
                </div>
              </div>
            </div>

            <hr />

            <div class="row pt-10 pb-20">
              <div class="col-md-6">
                <h3 class="text-uppercase line-height-1 mt-0 mt-sm-10 headingBranch">
                  GANDHI BHAG <span class="text-theme-color-2">BRANCH</span>
                </h3>
                <div class="double-line-bottom-theme-colored-2"></div>
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238130.15440691105!2d78.90769835448079!3d21.16100605022737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c0a5a31faf13%3A0x19b37d06d0bb3e2b!2sNagpur%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1713858403796!5m2!1sen!2sin"
                  width="100%"
                  height="375"
                  frameborder="0"
                  style={{ border: "0" }}
                  allowfullscreen
                ></iframe> */}

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.070700727705!2d79.10187187556141!3d21.14958448053014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c0cbd6fc6cb3%3A0x5edb15c47abd53cc!2sStar%20Convent%20%26%20Highschool!5e0!3m2!1sen!2sin!4v1714639347928!5m2!1sen!2sin"
                  width="100%"
                  height="375"
                  frameborder="0"
                  style={{ border: "0" }}
                  allowfullscreen
                ></iframe>
              </div>

              <div class="col-md-6">
                <h3 class="text-uppercase line-height-1 mt-0 mt-sm-10 headingBranch">
                  kALAMNA <span class="text-theme-color-2">BRANCH</span>
                </h3>
                <div class="double-line-bottom-theme-colored-2"></div>

                {/* <form
                  name="contact_form"
                  class=""
                  action="http://www.littlesenators.com/SendMail.php"
                  method="post"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group mb-30">
                        <input
                          id="form_name"
                          name="name"
                          class="form-control"
                          type="text"
                          placeholder="Your Name"
                          required=""
                          size="20"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-30">
                        <input
                          id="form_email"
                          name="email"
                          class="form-control required email"
                          type="email"
                          placeholder="Your Email"
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group mb-30">
                        <input
                          id="form_subject"
                          name="subject"
                          class="form-control subject required"
                          type="text"
                          placeholder="Subject"
                          size="20"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-30">
                        <input
                          id="form_phone"
                          name="contact"
                          class="form-control required"
                          type="text"
                          placeholder="Contact No."
                          size="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-30">
                    <textarea
                      id="form_message"
                      name="feedback"
                      class="form-control"
                      rows="7"
                      placeholder="Enter Your Feedback"
                      cols="20"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-flat btn-default bg-hover-theme-colored mr-5"
                      data-loading-text="Please wait..."
                    >
                      Send your feedback
                    </button>
                    <button
                      type="reset"
                      class="btn btn-default btn-flat btn-theme-color-2 bg-hover-theme-colored text-white"
                    >
                      Reset
                    </button>
                  </div>
                </form> */}

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4478.846743292629!2d79.14376417971144!3d21.176287574053614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c74dc44b5ba1%3A0x44af289f3c1faa7b!2sStar%20convent%20Pre%20School%20-%20Best%20kindergarten%20in%20Nagpur!5e0!3m2!1sen!2sin!4v1714639707998!5m2!1sen!2sin"
                  width="100%"
                  height="375"
                  frameborder="0"
                  style={{ border: "0" }}
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
