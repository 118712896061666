import React from 'react'
import './Vision.css'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
const Vision = () => {
  return (
    <>
    <Header/>
      <section class="inner-header divider layer-overlay overlay-theme-colored-4 vision" data-bg-img="images/bg-vision.jpg">
<div class="container pt-60 pb-40">
<div class="section-content">
<div class="row"> 
<div class="col-md-12 vision-box">
<h2 class="text-theme-color-2 font-36">Vision & Mission</h2>
<ol class="breadcrumb text-left mt-10 white">
{/* <li><a href="index-2.html">Home</a></li>

<li class="active">Vision & Mission</li> */}
</ol>
</div>
</div>
</div>
</div>
</section>

<section className='vision-box'>
<div class="container">
<div class="section-content">
<div class="row pt-40 pb-30">

<div class="col-md-4">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Our <span class="text-theme-color-2">Vision</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">Gradually He/She learn to pay attention, seeing more clearly small details in 
things around He/Her . Children begin to observe and appropriate the 
environment. This is the key in helping a child to discover how to learn.</p>
</div>

<div class="col-md-4">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Our <span class="text-theme-color-2">Mission</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>Working with monthly themes, other curricular activitiesw are playfully integrated. Basic topics in the all area of geography history, art and music will be introduced. Physical exercise is a part of our program.</p>
</div>

<div class="col-md-4">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Explore <span class="text-theme-color-2">Inclinations</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p>Express Thoughts, Nurture Talent, Develop Confidence, Identify Dreams, Have 3600 Growth. All beginnings require that you unlock new doors</p>
</div>

</div>
</div>
</div>
</section>
<Footer/>

    </>
  )
}

export default Vision

