import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';




export default function FormDialog() {
    const [open, setOpen] = React.useState(true);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;
              console.log(email);
              handleClose();
            },
          }}
        >
          <DialogTitle style={{color:"DeepPink"}}>Want to know More</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Give us your contact number our team shortly contact you
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="phone"
              label="Phone Number"
              type="number"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{color:"grey"}}>Cancel</Button>
            <Button type="submit">submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }