import React from 'react'
import SliderMain from '../slider/SliderMain'
import Wellcome from '../wellcome/Wellcome'
import KeyPillers from '../keyPillers/KeyPillers'
import LearningFramework from '../learningFramework/LearningFramework'
import ThoughtShaping from '../thoughtShaping/ThoughtShaping'
import SportsPrograms from '../sportsPrograms/SportsPrograms'
import ArtsPrograms from '../artsPrograms/ArtsPrograms'
import InfraSTructure from '../infraSTructure/InfraSTructure'
import Educators from '../educators/Educators'
import GRades from '../gRades/GRades'
import University from '../university/University'
import OurAlumni from '../ourAlumni/OurAlumni'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import InstaFeed from '../instaFeed/InstaFeed'
import SchoolLife from '../schoolLife/SchoolLife'


const FrontPage = () => {
  return (
    <div>



    
    <Header/>
      <SliderMain/>
      <Wellcome/>
      <GRades/>
      <KeyPillers/>
      {/* <LearningFramework/> */}
      <ThoughtShaping/>
      <SportsPrograms/>
      <ArtsPrograms/>
      <InfraSTructure/>
      <Educators/>
      {/* <University/> */}
      {/* <OurAlumni/>
      <InstaFeed/> */}
      {/* <SchoolLife/> */}
      <Footer/>
    </div>
  )
}

export default FrontPage
