import React from 'react'
import './Gallery.css'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'
const Gallery = () => {
  return (
    <>
    <Header/>
    <section class="inner-header divider layer-overlay overlay-theme-colored-4 gallery" data-bg-img="images/bg-gallery.jpg">
<div class="container pt-60 pb-40">
<div class="section-content">
<div class="row"> 
<div class="col-md-12 gallery-box">
<h2 class="text-theme-color-2 font-36">Gallery</h2>
<ol class="breadcrumb text-left mt-10 white">
{/* <li><a href="index-2.html">Home</a></li>
<li class="active">Gallery</li> */}
</ol>
</div>
</div>
</div>
</div>
</section>

<section className='gallery-box'>
<div class="container">
<div class="section-content">

<div class="row pt-40 pb-30" id="computer">

<div class="col-md-12">

<div id="grid" class="gallery-isotope grid-4 gutter clearfix">
 
<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/1a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/1a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/2a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/2a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/3a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/3a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/4a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/4a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/5a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/5a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/6a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/6a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/7a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/7a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/8a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/8a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/9a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/9a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/10a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/10a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/11a.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/11a.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

<div class="gallery-item">
<div class="thumb">
<img class="img-fullwidth" src="https://littlesenators.com/images/gallery/12.jpg" alt="project" width="500" height="429"/>
<div class="overlay-shade"></div>
<div class="icons-holder">
<div class="icons-holder-inner">
<div class="styled-icons icon-md icon-bordered icon-circled icon-theme-color-2">
<a data-lightbox="image" href="https://littlesenators.com/images/gallery/12.jpg"><i class="fa fa-plus"></i></a>
</div>
</div>
</div>
</div>
</div>

</div>

</div>

</div>

</div>
</div>
</section>
  <Footer/>
    </>
  )
}

export default Gallery
