import React from 'react'
import './SportsPrograms.css'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SportsPrograms = () => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 250,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <section class="programs-sec Sports-new-sec SportsPrograms " id="SportsPrograms">
			<div class="container">
				<div class="sec-heading">
					<h5>Sports Programs</h5>
					<p>Our students can choose from a range of engaging sports programs that are delivered by experienced coaches, empowering them to compete at various national and international sporting events.</p>
				</div>
				<div class="gallery-carosel">
					<div class="gallery">
						<div class="owl-carousel owl-theme gallery-carousel">

                        <Slider {...settings}>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg1" ></div>
										<div class="block-overlay">
											<h4>Fencing ^</h4>
											<p>
												Enhances strategic thinking and concentration through combat and duelling.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg2"></div>
										<div class="block-overlay">
											<h4>Martial Arts ^</h4>
											<p>
												Teaches various disciplines of self-defence or combat techniques.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg3" ></div>
										<div class="block-overlay">
											<h4>Archery ^</h4>
											<p>
												Creates focus, discipline and precision while promoting a sense of calm.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg4"></div>
										<div class="block-overlay">
											<h4>Badminton ^</h4>
											<p>
												Develops agility, hand-eye coordination and cardiovascular fitness for a well-rounded physical skill set.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg5" ></div>
										<div class="block-overlay">
											<h4>Basketball ^</h4>
											<p>
												Cultivates teamwork, strategic thinking and physical fitness while fostering a competitive spirit and strong social skills.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg6" ></div>
										<div class="block-overlay">
											<h4>Chess ^</h4>
											<p>
												Sharpens cognitive abilities, critical thinking and problem-solving skills through strategic gameplay.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg7" ></div>
										<div class="block-overlay">
											<h4>Cricket ^</h4>
											<p>
												Instills teamwork, patience and strategic thinking while promoting physical fitness and coordination.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg8"></div>
										<div class="block-overlay">
											<h4>Capoeira ^</h4>
											<p>
												Combines elements of dance and acrobatics to form Brazilian martial arts techniques.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg9" ></div>
										<div class="block-overlay">
											<h4>Football ^</h4>
											<p>
												Promotes teamwork, discipline and physical fitness while instilling a sense of camaraderie and sportsmanship.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg10"></div>
										<div class="block-overlay">
											<h4>Gymnastics ^</h4>
											<p>
												Involves exercises and performances requiring physical strength, flexibility and coordination.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg11" ></div>
										<div class="block-overlay">
											<h4>Shooting ^</h4>
											<p>
												Develops focus, precision and mental resilience, emphasizing responsibility and respect for safety.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg12" ></div>
										<div class="block-overlay">
											<h4>Skating ^</h4>
											<p>
												Enhances balance, coordination and cardiovascular health while providing a recreational outlet for physical activity.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg13" ></div>
										<div class="block-overlay">
											<h4>Swimming ^</h4>
											<p>
												Builds endurance, strength and overall fitness, promoting a lifelong skill and a healthy lifestyle.											</p>
										</div>
									</div>
								</div>
							</div>
														<div class="item">
								<div class="programs-card">
									<div class="block">
										<div class="bg bg14" ></div>
										<div class="block-overlay">
											<h4>Table Tennis ^</h4>
											<p>
												Improves reflexes, hand-eye coordination and concentration in a fast-paced and socially engaging setting.											</p>
										</div>
									</div>
								</div>
							</div>
                            </Slider>
													</div>
					</div>
				</div>
			</div>
		</section>
  )
}

export default SportsPrograms
