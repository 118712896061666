import React from 'react'
import './Educators.css'
import image1 from '../../assets/images/BHIS-EducatorsSection.jpg'
const Educators = () => {
  return (
    <section class="educators-sec" id="educators">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 m-auto">
                <div class="sec-heading">
                    <h5>Teachers who inspire</h5>
                    <p>Our educators are our torchbearers who bring ‘Thoughtful Schooling’ to life and nurture every Billabonger into bold, confident young adults, ready to face the world!<br/>Educators are the torchbearers, the ones who transform a school into ‘The School of Thought’. At , they undergo a thorough screening process before their in-house training, which helps them bring ‘thoughtful schooling’ to life.
Along with our learners, our teachers are encouraged to be innovative in their approach towards guiding and mentoring students in learning and up-skilling.<br/><br/>Our educators are:</p>
                </div>
                <div class="nature-sec">
<ul>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/Open-minded.jpg" alt="icon" />Open-minded</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/Research-oriented.jpg" alt="icon" />Research oriented</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/Creativethinkers.jpg" alt="icon" />Creative thinkers</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/cultural-dexterity.jpg" alt="icon" />Have cultural dexterity</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/Empathic.jpg" alt="icon" />Empathic</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/new-age-pedagogy.jpg" alt="icon" />Skilled in delivering new-age pedagogy</li>
<li><img decoding="async" src="https://www.billabonghighschool.com/wp-content/uploads/2024/02/Certified-subject-matter-experts.jpg" alt="icon" />Certified subject matter experts</li>
</ul>
</div>
            </div>
            <div class="col-lg-6 col-md-9 m-auto edu-img"> 
                <img loading="lazy" src={image1} alt="TEACHERS WHO INSPIRE" width="600" height="400" class="w-100 h-100 rounded-4"/>
            </div>
        </div>
    </div>
</section>
  )
}

export default Educators
