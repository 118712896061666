import React from 'react'
import grade0 from '../../assets/images/PLayschool_1920-x-760.jpg'
import grade1 from '../../assets/images/Nursery_1920-x-760 (1).jpg'
import grade2 from '../../assets/images/Jr-kg_1920-x-760.jpg'
import grade3 from '../../assets/images/Sr-kg_1920-x-760.jpg'
import './GRades.css'
const GRades = () => {
	return (
		<section class="infra-life infra-desk gRades" id="gRades">
			<div class="container">
				<div>
					<div class="d-flex custom">
						<div class="sec-heading">
							<h5>GRADES</h5>
						</div>
					</div>
					<div class="infra-content">
						<p>Our schools enable thoughtful learning right from the formative years up to high school.</p>
					</div>
					<div class="tab-content" id="pills-tabContent">
						<div class="tab-pane fade show active" id="expression" role="tabpanel" aria-labelledby="ex-pression" tabindex="0">
							{/* <!-- inside-tab --> */}
							<div class="rooms-sec">
								<div class="room-one">
									<ul class="expression-tab mb-0" id="room-tab" role="tablist" aria-orientation="vertical">
										<li class="nav-link active" id="room-music0-tab" data-bs-toggle="pill" data-bs-target="#room-music0" type="button" role="tab" aria-controls="room-music0" aria-selected="true">Playschool</li>
										<li class="nav-link " id="room-music1-tab" data-bs-toggle="pill" data-bs-target="#room-music1" type="button" role="tab" aria-controls="room-music1" aria-selected="false">Nursery</li>
										<li class="nav-link " id="room-music2-tab" data-bs-toggle="pill" data-bs-target="#room-music2" type="button" role="tab" aria-controls="room-music2" aria-selected="false">Junior KG</li>
										<li class="nav-link " id="room-music3-tab" data-bs-toggle="pill" data-bs-target="#room-music3" type="button" role="tab" aria-controls="room-music3" aria-selected="false">Senior KG</li>
										<li class="nav-link " id="room-music4-tab" data-bs-toggle="pill" data-bs-target="#room-music4" type="button" role="tab" aria-controls="room-music4" aria-selected="false">Primary School</li>
										<li class="nav-link " id="room-music5-tab" data-bs-toggle="pill" data-bs-target="#room-music5" type="button" role="tab" aria-controls="room-music5" aria-selected="false">Middle School</li>
										<li class="nav-link " id="room-music6-tab" data-bs-toggle="pill" data-bs-target="#room-music6" type="button" role="tab" aria-controls="room-music6" aria-selected="false">High School</li>
									</ul>
									<div class="zone-1 tab-content" id="zone1">
										<div class="tab-pane fade show active" id="room-music0" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src={grade0} alt="PLayschool" class="w-100" />
															<div class="m-txt">
																<h3>Playschool</h3>
																<p><p><strong>Age: 2 to 3 years</strong></p>
																	<p>Our program focuses on future skills by developing language, math, and science concepts in your children through art, music, dance, and play.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music1" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src={grade1} alt="Nursery" class="w-100" />
															<div class="m-txt">
																<h3>Nursery</h3>
																<p><p><strong>Age: 3 to 4 years</strong></p>
																	<p>Our Nursery program provides children with opportunities to think, ask questions, and build their own learning through the learning stations spread across the school.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music2" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src={grade2} alt="Nursery" class="w-100" />
															<div class="m-txt">
																<h3>Junior KG</h3>
																<p><p><strong>Age: 4 to 5 years</strong></p>
																	<p>Children in Junior KG get to “invent, create and discover” by building hypotheses, actively experimenting, imitating, practicing, and roleplaying.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music3" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src={grade3} alt="Nursery" class="w-100" />
															<div class="m-txt">
																<h3>Senior KG</h3>
																<p><p><strong>Age: 5 to 6 years</strong></p>
																	<p>The power sheets, learning stations, table time, and choice time are inbuilt with processes that develop skills of focus, concentration, and perseverance that prepare children to comfortably transition to primary school and enable them to excel there.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music4" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Primary_final.jpg" alt="" class="w-100" />
															<div class="m-txt">
																<h3>Primary School</h3>
																<p><p><strong>Grades:1 to 4 (Age: 5 to 9) </strong></p>
																	<p>Our program is crafted with the vision of enabling young learners to be educated in unique, creative and contemporary ways. Students at Billabong primary schools are encouraged to adopt a scientific attitude and ask questions, build their imagination and express themselves freely and clearly.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music5" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Middle_final.jpg" alt="" class="w-100" />
															<div class="m-txt">
																<h3>Middle School</h3>
																<p><p><strong>Grades: 5 to 8 (Age: 10 to 13)</strong></p>
																	<p>At this stage, our students are provided with an environment that focuses on academic excellence, rational thinking, extra-curricular activities, as well as career guidance. The carefully designed curriculum enables the young learners to become responsible, confident and thoughtful leaders of the future.</p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="tab-pane fade " id="room-music6" role="tabpanel" aria-labelledby="room-music-tab" tabindex="0">
											<div class="room-1">
												<div class="owl-carousel room-carousl">
													<div class="item">
														<div class="m-img">
															<img loading="lazy" src="https://www.billabonghighschool.com/wp-content/uploads/2023/12/Sr-school_final.jpg" alt="" class="w-100" />
															<div class="m-txt">
																<h3>High School</h3>
																<p><p><strong>Grades: 9 to 12 (AS & A levels) (Age: 14 to 17)</strong></p>
																	<p>During these years, our focus is on ensuring academic excellence while also enabling them to be future-ready. Our thoughtful programs prepare students for life beyond school, with an abundance of resources at thier disposal including career guidance programs, internships, summer school programs, university tie-ups, and expert counselling sessions. </p></p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default GRades
