import React from 'react'
import './About.css'
import Footer from '../../footer/Footer'
import Header from '../../header/Header'
const About = () => {
  return (
    <>
<Header/>
<section class="inner-header divider layer-overlay overlay-theme-colored-4 about-main" data-bg-img="images/bg-aboutus.jpg">
<div class="container pt-60 pb-40">
<div class="section-content">
<div class="row"> 
<div class="col-md-12 about-box">
{/* <h2 class="text-theme-color-2 font-36">About us</h2> */}
<ol class="breadcrumb text-left mt-10 white">
{/* <li><a href="index-2.html">Home</a></li> */}

{/* <li class="active">About us</li> */}
</ol>
</div>
</div>
</div>
</div>
</section>
    <section className='about'>
<div class="container">
<div class="section-content">
<div class="row pt-40">
<div class="col-md-12">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10 about-h3">About <span class="text-theme-color-2">Us</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">Little Senators is the Delhi's Premium Pre School & Daycare who has established a milestone in area of child education in short span of time.Little Senators is one of the best,safe,innovative & trusted Pre School and Daycare in Delhi.Little Senators is a unique and innovative pre-school under the banner of Little Senators Educare, based out of Vasant Vihar, New Delhi, committed to deliver high quality of education programs that equip students with a solid foundation in integrated curriculum with technological savvy, methods, materials, and interactions needed to create the environment that leads to enriched learning opportunities for every child.</p>
</div>
</div>
<hr/>
<div class="row pt-10 pb-20">
<div class="col-md-12">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">School <span class="text-theme-color-2">Timing</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">School functions from Monday to Friday: From 9.00AM to 12.30PM</p>
<p align="justify">Day Care: From 9.00AM to 4.00PM</p>

</div>
</div>

<hr/>


<hr/>
<div class="row pt-10 pb-20">
<div class="col-md-12">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Our <span class="text-theme-color-2">Philosophy</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">Little senators follows the play-way method of learning. Many exercise, specially at the early childhood level are designed to draw the childs attention to the sensory properties of objects within his/her environment sizes, shapes, 
colors, textures, weights, sounds etc.</p>
</div>
</div>

<hr/>

<div class="row pt-10 pb-20">
<div class="col-md-12">
<h3 class="text-uppercase line-height-1 mt-0 mt-sm-10">Infra <span class="text-theme-color-2">
Structure</span></h3>
<div class="double-line-bottom-theme-colored-2"></div>
<p align="justify">The School is housed in a well planned building. The class rooms are large, 
airy and fully air-conditioned with well maintained air-purifiers.</p>
<p align="justify">Our Gymebouce area is beautifully designed, keeping in mind, the safety of the 
children, which makes it a wonder full happy and fun there for them.</p>
<p align="justify">For outdoor fun, there is a splash pool for hot summer days and a sandpit in 
which the children can build their sand castles.</p>
</div>
</div>

</div>
</div>
</section>
<Footer/>
</>
  )
}

export default About
